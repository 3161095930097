import * as React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-lib";
import { colors } from "./colorVariables";
import { Typography } from "./Typography";

const StyledButtonWrapper = styled.div`
  display: inline-block;
  position: relative;
  transition: all 0.3s ease;
  padding: 1rem 0;
  z-index: 2;
  a {
    padding: 1rem 1rem;
    background: ${colors.blue.hex};
    display: inline-block;
  }
  a:hover {
    background: ${colors.darkBlue.hex};
    transition: all 0.3s ease;
  }
  a[disabled],
  a[disabled]:hover {
    pointer-events: none;
    background: ${colors.gray.hex};
    color: #777777;
  }
  p {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: white;
    text-align: center;
  }
`;

const Button = ({
  to,
  target,
  children
}: {
  to: string;
  target?: string;
  children: React.ReactChild;
}) => {
  return (
    <StyledButtonWrapper>
      <WmkLink to={to} target={target}>
        <Typography.Button>{children}</Typography.Button>
      </WmkLink>
    </StyledButtonWrapper>
  );
};

export default Button;
