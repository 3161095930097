import * as React from "react";

const CupcakeIcon = ({ width = "27.808", height = "40.768" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.808 40.768">
      <path
        id="Path_1901"
        data-name="Path 1901"
        d="M4.638,30.634H23.359l-2.219,8.48H7.061Zm-2.91-2.868L2.672,26.7H25.3l.808,1.216-.782,1.059H2.7ZM5.1,23.831,5.8,22.774H22.107L22.7,24l-.574,1.054H5.821ZM8.569,19.9l.445-1.05H19.033l.388,1.227-.369,1.048H9.036Zm4.279-9.117h2.165V17.19H12.848Zm-.274-4.546,1.443-3.711,1.574,3.723-1.5,1.5ZM14.748,0,13.215.022,10.836,6.135l.186.885,2.1,2.1H12.021l-.827.827V17.19H8.466l-.762.5-.788,1.861-.011.617.361.946H5.349l-.692.374L3.437,23.355l-.021.873.484.821H2.3l-.618.278L.028,27.188,0,28.255l1.655,2.068.646.311h.617l2.724,9.534.8.6H21.779l.8-.618,2.49-9.516h.675l.666-.336,1.374-1.862.023-.949-1.374-2.068-.689-.369H24.015l.347-.639.016-.759-1.012-2.068-.743-.464H20.807l.267-.759.008-.525-.655-2.068-.789-.578H16.668V9.951l-.827-.827h-.789l2.1-2.1.177-.907Z"
        fill="#119cb2"
      />
    </svg>
  );
};

export default CupcakeIcon;
