import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import { ChildCardFieldsQuery } from "../fragments/NodeBlockChildCard";
import { Child, Children } from "../classes/Child";
import { Col, Container, Row } from "react-bootstrap";
import { Typography } from "../components/ui/Typography";
import CalendarIcon from "../components/Icons/CalendarIcon";
import CupcakeIcon from "../components/Icons/CupcakeIcon";
import AddPersonIcon from "../components/Icons/AddPersonIcon";
import Button from "../components/ui/MainButton";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { colors } from "../components/ui/colorVariables";
import { WmkLink } from "wmk-link";
import ChildCard from "../components/ui/ChildCard";
import { useState, useEffect } from "react";
import { WmkSeo } from "wmk-seo";

const PageWrap = styled(Container)`
  margin: 5vh 0;
`;

const ChildDetails = styled.div`
  display: flex;
  h5 {
    display: inline-block;
    padding: 0 0 0 1rem;
    margin: 0;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 20vw;
  }
  @media only screen and (max-width: 500px) {
    padding-left: 10vw;
  }
  @media only screen and (max-width: 400px) {
    padding-left: 0vw;
  }
`;

const InfoCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .row {
    margin: 1vh 0;
  }
  h1 {
    color: ${colors.blue.hex};
  }
  @media only screen and (max-width: 767px) {
    h1 {
      text-align: center;
      font-size: 30px !important;
    }
    .button-row > div:first-child {
      display: flex;
      justify-content: center;
    }
  }
  @media only screen and (min-width: 992px) {
    padding-left: 5rem;
  }
  @media only screen and (min-width: 1200px) {
    padding-left: 10rem;
  }
  @media only screen and (min-width: 1400px) {
    padding-left: 13rem;
  }
`;

const DecriptionCol = styled(Col)`
  text-align: center;
  background: ${colors.lightGray.hex};
  padding: 10vh 10vw;
  h4 {
    margin-bottom: 2rem;
    color: ${colors.blue.hex};
  }
`;
const ViewMoreContainer = styled(Container)`
  margin: 10vh 0;
  .row {
    display: flex;
    justify-content: center;
  }
  .child-card-col {
    margin-top: 5vh;
  }
`;

const TextCol = styled(Col)`
  display: flex;
  justify-content: center;
  h4 {
    text-align: center;
  }
  p {
    justify-content: center;
  }
`;

const ImageCol = styled(Col)`
  @media only screen and (max-width: 767px) {
    margin: 5vh 0;
    margin-top: 5vh;
  }
`;

const ChildNode = ({
  data
}: {
  data: {
    child: ChildCardFieldsQuery;
    children: {
      edges: {
        node: ChildCardFieldsQuery;
      }[];
    };
    childCardCollections: {
      edges: {
        node: {
          childrenCardsId: string;
          contentfulchildren: ChildCardFieldsQuery;
        };
      }[];
    };
    site: { meta: { description: string; siteUrl: string; title: string } };
  };
}) => {
  const child = new Child(data.child);
  const children = new Children(data.children.edges);
  const condensedChildList = data?.childCardCollections?.edges.flatMap(
    (collection) => {
      return collection?.node?.contentfulchildren;
    }
  );
  const condensedRestructure = condensedChildList.map((child) => {
    return { node: child };
  });

  const currentChildren = new Children(condensedRestructure);

  const sortedChildren = currentChildren.list.sort((a,b) => {
    if (a.dateAdded < b.dateAdded) {
      return -1;
    }
    if (a.dateAdded > b.dateAdded) {
      return 1;
    }
    return 0;
  })

  let longestWaitingChildren: Child[] = []
  sortedChildren.forEach((sortedChild, i) => {
    if(sortedChild.name !== child.name && i < 4) {
      longestWaitingChildren.push(sortedChild)
    }
  })

  if (longestWaitingChildren.length > 3) {
    longestWaitingChildren.pop()
  }

  const [viewWidth, setViewWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const winWidth = window.innerWidth;
      setViewWidth(winWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const getMonthName = (month: number) => {
    const date = new Date();
    date.setMonth(month - 1);

    return date.toLocaleString("en-US", {
      month: "long"
    });
  };

  return (
    <Layout>
      <WmkSeo.Meta
        title={child.name}
        siteTitle={data?.site?.meta?.title}
        baseUrl={data?.site?.meta?.siteUrl}
        path="/"
        slug={child.name}
        description={data?.site?.meta?.description}
        ogImageUrl={child?.image?.src}
        twitterHandle=""
        twitterImageUrl=""
      />
      <>
        <PageWrap fluid>
          <Row style={{ margin: "5vh 0 10vh 0" }}>
            <Col>
              <Container>
                <Row>
                  <WmkLink
                    to="/sponsor-a-child"
                    style={{ textDecorationColor: colors.blue.hex }}>
                    <Typography.P
                      style={{
                        display: "flex",
                        color: colors.blue.hex,
                        textDecoration: "none"
                      }}>
                      {"< Back"}
                    </Typography.P>
                  </WmkLink>
                </Row>
                <Row>
                  <InfoCol md={6} style={{ order: viewWidth > 768 ? 1 : 2 }}>
                    <Row>
                      <Typography.H1>{child.name}</Typography.H1>
                    </Row>
                    <Row>
                      <ChildDetails>
                        <CalendarIcon width="33" height="35" />
                        <Typography.H5>Birth Month</Typography.H5>
                        <Typography.H5 style={{ color: colors.blue.hex }}>
                          {getMonthName(child.birthMonth)}
                        </Typography.H5>
                      </ChildDetails>
                    </Row>
                    <Row>
                      <ChildDetails>
                        <CupcakeIcon width="33" />
                        <Typography.H5>Age</Typography.H5>
                        <Typography.H5
                          style={{
                            color: colors.blue.hex
                          }}>{`${child.age} years old`}</Typography.H5>
                      </ChildDetails>
                    </Row>
                    <Row>
                      <ChildDetails>
                        <AddPersonIcon width="33" />
                        <Typography.H5>Waiting since</Typography.H5>
                        <Typography.H5 style={{ color: colors.blue.hex }}>
                          {child.dateAdded.toLocaleDateString()}
                        </Typography.H5>
                      </ChildDetails>
                    </Row>
                    <Row className="button-row">
                      <Col>
                        <Button
                          to={child?.sponsorLink?.url}
                          target={child?.sponsorLink?.url}>
                          {child.sponsorLink.linkText}
                        </Button>
                      </Col>
                    </Row>
                  </InfoCol>
                  <ImageCol style={{ order: viewWidth > 768 ? 2 : 1 }}>
                    <GatsbyImage
                      image={child.image.gatsby}
                      alt={child.image.alt}
                    />
                  </ImageCol>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <DecriptionCol>
              <Typography.H4>{`About ${child.name}`}</Typography.H4>
              <Typography.P>{child.description}</Typography.P>
            </DecriptionCol>
          </Row>
          <Row>
            <ViewMoreContainer>
              <Row>
                <TextCol>
                  <Typography.H4>
                    Longest Waiting Sponsorship Opportunities
                  </Typography.H4>
                </TextCol>
              </Row>
              <Row>
                <TextCol>
                  <Typography.H6>
                    These children have been waiting for sponsorship the
                    longest.
                  </Typography.H6>
                </TextCol>
              </Row>
              <Row>
                <TextCol>
                  <WmkLink
                    to="/sponsor-a-child"
                    style={{ textDecorationColor: colors.blue.hex }}>
                    <Typography.Footer
                      style={{
                        display: "flex",
                        color: colors.blue.hex,
                        textDecoration: "none"
                      }}>
                      {"View All >"}
                    </Typography.Footer>
                  </WmkLink>
                </TextCol>
              </Row>
              <Row>
                {Array.isArray(longestWaitingChildren) &&
                  longestWaitingChildren.map((child: Child, i) => {
                    return (
                      <Col
                        md={3}
                        key={child.name + i}
                        className="child-card-col">
                        <ChildCard
                          name={child.name}
                          age={child.age}
                          link={child.sponsorLink}
                          image={child.image}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </ViewMoreContainer>
          </Row>
        </PageWrap>
      </>
    </Layout>
  );
};

export default ChildNode;

export const query = graphql`
  query childQuery($name: String) {
    child: contentfulBlockChildCard(name: { eq: $name }) {
      ...NodeBlockChildCardFields
    }
    children: allContentfulBlockChildCard(limit: 3) {
      edges {
        node {
          ...NodeBlockChildCardFields
        }
      }
    }
    childCardCollections: allContentfulBlockChildrenCards(
      filter: {
        childrenCardsId: {
          in: [
            "Sponsor a Child- Village Child- Head shot"
            "Sponsor a Child - UIS Children Headshots"
          ]
        }
      }
    ) {
      edges {
        node {
          childrenCardsId
          contentfulchildren {
            ...NodeBlockChildCardFields
          }
        }
      }
    }
    site {
      meta: siteMetadata {
        description
        siteUrl
        title
      }
    }
  }
`;
