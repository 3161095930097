import * as React from "react";

const CalendarIcon = ({ width = "32.699", height = "35.367" }) => {
  return (
    <svg
      id="Group_4277"
      data-name="Group 4277"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32.699 35.367">
      <path
        id="Path_1889"
        data-name="Path 1889"
        d="M1.655,11.472h29.39v22.24H1.655ZM25.439,6.724V3.93h5.606V9.817H1.655V3.93h6V6.724H9.307V3.93H23.785V6.724ZM23.785,0V2.275H9.307V0H7.652V2.275H.827L0,3.1V34.539l.827.827H31.872l.827-.827V3.1l-.827-.827H25.439V0Z"
        fill="#119cb2"
      />
      <path
        id="Path_1890"
        data-name="Path 1890"
        d="M20.017,65.5l-.517.517V70.36l.517.517H24.36l.517-.517V66.017L24.36,65.5Zm3.826,4.343H20.534V66.534h3.309Z"
        transform="translate(-15.467 -51.953)"
        fill="#119cb2"
      />
      <path
        id="Path_1891"
        data-name="Path 1891"
        d="M52.35,65.5l-.517.517V70.36l.517.517h4.343l.517-.517V66.017l-.517-.517Zm3.826,4.343H52.867V66.534h3.309Z"
        transform="translate(-41.113 -51.953)"
        fill="#119cb2"
      />
      <path
        id="Path_1892"
        data-name="Path 1892"
        d="M117.017,65.5l-.517.517V70.36l.517.517h4.343l.517-.517V66.017l-.517-.517Zm3.826,4.343h-3.309V66.534h3.309Z"
        transform="translate(-92.405 -51.953)"
        fill="#119cb2"
      />
      <path
        id="Path_1893"
        data-name="Path 1893"
        d="M84.684,65.5l-.517.517V70.36l.517.517h4.343l.517-.517V66.017l-.517-.517Zm3.826,4.343H85.2V66.534H88.51Z"
        transform="translate(-66.759 -51.953)"
        fill="#119cb2"
      />
      <path
        id="Path_1894"
        data-name="Path 1894"
        d="M20.017,96.5l-.517.517v4.343l.517.517H24.36l.517-.517V97.017L24.36,96.5Zm3.826,4.343H20.534V97.534h3.309Z"
        transform="translate(-15.467 -76.542)"
        fill="#119cb2"
      />
      <path
        id="Path_1895"
        data-name="Path 1895"
        d="M52.35,96.5l-.517.517v4.343l.517.517h4.343l.517-.517V97.017l-.517-.517Zm3.826,4.343H52.867V97.534h3.309Z"
        transform="translate(-41.113 -76.542)"
        fill="#119cb2"
      />
      <path
        id="Path_1896"
        data-name="Path 1896"
        d="M117.017,96.5l-.517.517v4.343l.517.517h4.343l.517-.517V97.017l-.517-.517Zm3.826,4.343h-3.309V97.534h3.309Z"
        transform="translate(-92.405 -76.542)"
        fill="#119cb2"
      />
      <path
        id="Path_1897"
        data-name="Path 1897"
        d="M84.684,96.5l-.517.517v4.343l.517.517h4.343l.517-.517V97.017l-.517-.517Zm3.826,4.343H85.2V97.534H88.51Z"
        transform="translate(-66.759 -76.542)"
        fill="#119cb2"
      />
      <path
        id="Path_1898"
        data-name="Path 1898"
        d="M20.017,127.5l-.517.517v4.343l.517.517H24.36l.517-.517v-4.343l-.517-.517Zm3.826,4.343H20.534v-3.309h3.309Z"
        transform="translate(-15.467 -101.13)"
        fill="#119cb2"
      />
      <path
        id="Path_1899"
        data-name="Path 1899"
        d="M52.35,127.5l-.517.517v4.343l.517.517h4.343l.517-.517v-4.343l-.517-.517Zm3.826,4.343H52.867v-3.309h3.309Z"
        transform="translate(-41.113 -101.13)"
        fill="#119cb2"
      />
      <path
        id="Path_1900"
        data-name="Path 1900"
        d="M84.684,127.5l-.517.517v4.343l.517.517h4.343l.517-.517v-4.343l-.517-.517Zm3.826,4.343H85.2v-3.309H88.51Z"
        transform="translate(-66.759 -101.13)"
        fill="#119cb2"
      />
    </svg>
  );
};

export default CalendarIcon;
