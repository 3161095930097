import { ChildCardFieldsQuery } from "../fragments/NodeBlockChildCard";
import { LinkQuery } from "../fragments/NodeLinkFields";
import { Img } from "./Img";

export class Child {
  name: string;
  age: number;
  image: Img;
  birthMonth: number;
  description: string;
  sponsorLink: LinkQuery;
  dateAdded: Date;
  constructor(node: ChildCardFieldsQuery) {
    const _node = { ...node };
    const dateAdded = new Date(_node.createdAt);
    // console.log("dateAdded", dateAdded);
    this.name = _node.name;
    this.age = _node.age;
    this.image = new Img(_node.image);
    this.birthMonth = _node.birthMonth;
    this.description = _node.description?.description;
    this.sponsorLink = _node.sponsorLink;
    this.sponsorLink = _node.sponsorLink;
    this.dateAdded = dateAdded;
  }
}

export class Children {
  list: Child[];
  length: number;
  constructor(edges: { node: ChildCardFieldsQuery }[]) {
    // console.log('edges: ', edges);
    const _list = Array.isArray(edges)
      ? edges.map((e) => new Child(e.node))
      : [];
    // const list = _list.reduce((filtered: Child[], node) => {
    //   if (!node.name.match(/schema/gi)) {
    //     filtered.push(node);
    //   }
    //   return filtered;
    // }, []);
    this.list = _list;
    this.length = _list.length;
  }
}