import * as React from "react";

const AddPersonIcon = ({ width = "33.892", height = "40.768" }) => {
  return (
    <svg
      id="Group_4279"
      data-name="Group 4279"
      xmlns="http://www.w3.org/2000/svg"
      xmlns-xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 33.892 40.768">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_4889"
            data-name="Rectangle 4889"
            width={width}
            height={height}
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_4278" data-name="Group 4278" clipPath="url(#clip-path)">
        <path
          id="Path_1903"
          data-name="Path 1903"
          d="M41.083,19.357A9.678,9.678,0,1,0,31.4,9.678a9.689,9.689,0,0,0,9.678,9.679m0-17.678a8,8,0,1,1-8,8,8.008,8.008,0,0,1,8-8"
          transform="translate(-24.184)"
          fill="#119cb2"
        />
        <path
          id="Path_1904"
          data-name="Path 1904"
          d="M114.666,142.088h-.936v1.974h-1.974V145h1.974v1.974h.936V145h1.974v-.936h-1.974Z"
          transform="translate(-86.061 -109.42)"
          fill="#119cb2"
        />
        <path
          id="Path_1905"
          data-name="Path 1905"
          d="M31.42,104.366a16.8,16.8,0,0,0-31.3,7.52q-.059.931-.119,1.861l25.072-.021a5.655,5.655,0,0,0,6.348-9.36M16.9,97.839a14.938,14.938,0,0,1,11.62,5.549q-.141-.007-.284-.007a5.653,5.653,0,0,0-4.885,8.5H1.963A14.984,14.984,0,0,1,16.9,97.839m11.336,15.017a3.818,3.818,0,1,1,3.818-3.818,3.822,3.822,0,0,1-3.818,3.818"
          transform="translate(0 -73.928)"
          fill="#119cb2"
        />
      </g>
    </svg>
  );
};

export default AddPersonIcon;
