import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import slugify from "slugify";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { Img } from "../../classes/Img";
import { LinkQuery } from "../../fragments/NodeLinkFields";
import { colors } from "./colorVariables";
import { Typography } from "./Typography";

const StyledContainer = styled(Container)`
  .sponsor-button {
    background: ${colors.blue.hex};
  }
  .details-button {
    background: ${colors.lightBlue.hex};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const NameLine = styled.div`
  background: ${colors.lightGray.hex};
  text-align: center;
  padding: 0.6rem 0;
  p {
    margin: 0;
  }
`;

const LinkCol = styled(Col)`
  text-align: center;
  text-transform: uppercase;
  padding: 0 !important;
  p {
    padding: 0.5rem 0;
    margin: 0;
    font-weight: 400 !important;
  }
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    background: black !important;
    a {
      color: white;
    }
  }
`;

const ChildCard = ({
  name,
  age,
  link,
  image
}: {
  name: string;
  age: number;
  link: LinkQuery;
  image: Img;
}) => {
  const nameSlug = name ? slugify(name).toLocaleLowerCase() : "";
  return (
    <StyledContainer>
      <Row>
        <Col>
          <Row>
            <GatsbyImage image={image.gatsby} alt={image.title} />
            <NameLine>
              <Typography.Footer>{`${name}, ${age} Years Old`}</Typography.Footer>
            </NameLine>
          </Row>
          <Row>
            <LinkCol className="sponsor-button">
              <WmkLink to={link.url} target={link.url}>
                <Typography.Footer style={{ color: "white" }}>
                  {/* {link.linkText ? link.linkText : `Sponsor ${name}`} */}
                  {link.linkText}
                </Typography.Footer>
              </WmkLink>
            </LinkCol>
            <LinkCol className="details-button">
              <WmkLink to={nameSlug ? `/${nameSlug}` : "#"}>
                <Typography.Footer>{"LEARN MORE"}</Typography.Footer>
              </WmkLink>
            </LinkCol>
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default ChildCard;
